import React                                  from 'react';
import { graphql }                            from 'gatsby';
import Img                                    from 'gatsby-image';
import { useMediaQuery, Box, Button, Center } from '@chakra-ui/react';
import { GiDiamondRing, GiWatch, GiShop }     from 'react-icons/gi';

import CallToAction       from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import Heading            from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer             from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import BrandsDisplay from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import Carousel           from '@interness/web-core/src/components/media/Carousel/Carousel';
import Link               from '@interness/web-core/src/components/elements/Link/Link';
import ConditionalWrapper from '@interness/web-core/src/components/structure/ConditionalWrapper/ConditionalWrapper';
import LiveAnnouncements  from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import RandomShopItems    from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';

function IndexPage({ data }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <SEO title={'Startseite'}/>
      <Carousel>
        {data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <ConditionalWrapper key={image.file.localFile.name}
                              condition={image.file.metadata && image.file.metadata.link_to}
                              wrapper={(children) => <Link to={image.file.metadata.link_to}>{children}</Link>}>
            <Img fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          </ConditionalWrapper>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Hohage</Heading>
          <Box>
            <p>Seien Sie herzlich willkommen bei Juwelier Hohage in Lüdenscheid, im Herzen der Stadt des Lichts.
              Juwelier Hohage ist Ihr Rolex und Tudor Konzessionär.</p>
            <p>Auf traditionelle Weise trägt Juwelier Hohage seit vielen Jahren in Lüdenscheid dazu bei, Ihre Wünsche
              und Träume bei Uhren, Schmuck und exklusive Trauringe und Eheringe zu erfüllen.</p>
            <p>Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Wilhelmstr. 34 in Lüdenscheid, wo
              wir uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.
              Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen
              ist für das Team von Juwelier Hohage selbstverständlich.</p>
            <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen,
              anzuprobieren und zu begutachten.
              In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck,
              moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.</p>
            <p>Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. Hier
              bekommen Sie unseren perfekten Service.</p>
            <p>BIS BALD IN LÜDENSCHEID<br/>IHRE FAMILIE MARKES</p>
          </Box>
          {/*<Flex flexWrap="wrap">*/}
          {/*  <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]} paddingRight={0}>*/}
          {/*    <FramedImage>*/}
          {/*      <Img fluid={data.laden.images[0].file.localFile.childImageSharp.fluid}/>*/}
          {/*    </FramedImage>*/}
          {/*  </Box>*/}
          {/*  <Box w={['100%', '100%', '50%', '50%']} p={[4, 4, 4, 12]}>*/}
          {/*    <p>Seien Sie herzlich willkommen bei Juwelier Hohage in Lüdenscheid, im Herzen der Stadt des Lichts.*/}
          {/*      Juwelier Hohage ist Ihr Rolex und Tudor Konzessionär.</p>*/}
          {/*    <p>Auf traditionelle Weise trägt Juwelier Hohage seit vielen Jahren in Lüdenscheid dazu bei, Ihre Wünsche*/}
          {/*      und Träume bei Uhren, Schmuck und exklusive Trauringe und Eheringe zu erfüllen.</p>*/}
          {/*    <p>Wir freuen uns auf Ihren Besuch in unserem modernen Geschäft in der Wilhelmstr. 34 in Lüdenscheid, wo*/}
          {/*      wir uns in entspannter Atmosphäre ausgiebig Zeit für Ihre Wünsche nehmen möchten.*/}
          {/*      Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen*/}
          {/*      ist für das Team von Juwelier Hohage selbstverständlich.</p>*/}
          {/*    <p>Wir laden Sie herzlich ein, bei Ihrem Besuch in unserem Geschäft Ihre Lieblingsstücke auszuwählen,*/}
          {/*      anzuprobieren und zu begutachten.*/}
          {/*      In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck,*/}
          {/*      moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires.</p>*/}
          {/*    <p>Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. Hier*/}
          {/*      bekommen Sie unseren perfekten Service.</p>*/}
          {/*    <p>BIS BALD IN LÜDENSCHEID<br/>IHRE FAMILIE MARKES</p>*/}
          {/*  </Box>*/}
          {/*</Flex>*/}
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Aktuelle Kollektionen</Heading>
          <Spacer height={40}/>
          <BrandsDisplay/>
        </section>
        <section>
          <h2 style={{ textAlign: 'center' }}>Abonnieren Sie unseren Newsletter</h2>
          <div style={{ textAlign: 'center' }}>
            <iframe src="https://tbf4c09b0.emailsys1a.net/240/1377/7108404ce3/subscribe/form.html?_g=1713304408"
                    frameBorder="0" width="100%" height="550"/>
          </div>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiWatch/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
    query {
        headerImages: directusMediaCollection(name: {eq: "home"}) {
            name
            images: media {
                file {
                    metadata {
                        link_to
                    }
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "home-mobil"}) {
            name
            images: media {
                file {
                    metadata {
                        link_to
                    }
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        laden: directusMediaCollection(name: {eq: "index"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 800, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
